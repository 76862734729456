/* CustomCalendar.css */

.react-calendar {
  border: none;
  width: 100%;
}

.disabled-date {
  cursor: not-allowed; 
  color: black;
}

.react-calendar__tile:disabled {
  background: none !important;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  background: none !important;
  border: none;
  font-size: 0;
  width: 0;
  height: 0;
  padding-right: 16px;
}

.react-calendar__navigation {
  display: flex;
  padding-right: 45px;
}


.react-calendar__navigation__prev-button::before {
  content: url('../../../assets/svg/button-left.svg'); 
  width: 24px;
  height: 24px; 
}

.react-calendar__navigation__next-button::before {
  content: url('../../../assets/svg/button-right.svg'); 
  width: 24px; 
  height: 24px; 
  margin-right: 10px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__label {
  order: 1;
  margin-left: 16px; 
  text-transform: uppercase;
  background: none !important;
  font-size: 20px;
  background-color: aqua;
  font-weight: bold;
  font-family: "Avenir LT Std", sans-serif; 
}

.react-calendar__navigation__arrow {
  order: 2;
}

.react-calendar__navigation__label__label {
  text-transform: uppercase;
}

.react-calendar__month-view__days__day--weekend {
  color: black;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}

.react-calendar__tile--active abbr {
  font-weight: bold;
}

.react-calendar__tile abbr {
  font-weight: bold;
}

.react-calendar__month-view__weekdays__weekday abbr {
  font-weight: normal;
  text-decoration: none; 
}

.react-calendar__tile--active {
  background-color: #FF4E5B;
  border-radius: 30px;
}

.react-calendar__tile--now{
  background: #D8DCDF; 
  border-radius: 30px;
}

.react-calendar__tile--active:enabled:hover{
  background-color: #FF4E5B;
  border-radius: 30px;
}

.react-calendar__tile:enabled:focus {
  background-color: #FF4E5B;

}
.react-calendar__tile:enabled:hover {
  background-color: #FF4E5B;
  border-radius: 30px;
}



