.option {
	display: flex;
	align-items: center;
	position: relative;
}

input[type="checkbox"] {
	border-radius: 2px;
	-webkit-transition: all 150ms;
	transition: all 150ms;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-align-items: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	cursor: pointer;
	height: 20px;
	width: 20px;
}

input[type="checkbox"] {
	accent-color: var(--p500) !important;
	color: none;
}

.multiSelectContainer input {
	background-color: none;
}

ul {
	bottom: 100%;
}

.optionListContainer {
	bottom: 100%;
}

.searchBox {
	border: none;
	font-size: 10px;
	min-height: 50px;
}
/* Change the color of the selected option */
.multiselect-item.selected {
    color: blue; /* Change to your desired color */
}

/* Optionally, change the background color of the selected option */
.multiselect-item.selected {
    background-color: lightgray; /* Change to your desired color */
}